$(document).ready(function () {
    // Auto login if user already logged
    if (!window.location.pathname.includes('engagements')) {
        checkUserLogin();
    }

    Vue.component("v-select", VueSelect.VueSelect);

    Vue.component('job-card', {
        props: {
            job: {type: Object, required: true},
            images: {type: Object, required: true},
            dragging: {type: Boolean, required: true}
        },
        computed: {
            localisation() {
                return this.job.city ?? this.job.regionLib ?? null
            }
        },
        template: `
            <div class="card shadow-lg text-blue-900 text-center py-10 rounded-lg flex flex-col justify-between m-2 cursor-pointer" @mouseup="showOffer">
                <div class="logo mb-2">
                    <img :src="job.logo" alt="" class="w-1/5 mx-auto">
                </div>
                <div class="title-company">
                    <span class="uppercase">{{ job.company }}</span>
                </div>
                <div class="job-title text-blue-800 text-md font-bold">
                    {{ job.title }}
                </div>
                <div class="job-location" v-if="localisation">
                    <img :src="images.locationPin" alt="locationPin" class="w-4 inline">
                    <span class="text-blue-900 font-hairline">{{ localisation }}</span>
                </div>
                <div class="job-contact mt-2" v-if="job.type">
                    <img :src="images.suitcase" alt="suitcase" class="w-4 inline">
                    <span class="text-blue-900 font-bold">{{ job.type }}</span>
                </div>
            </div>
        `,
        methods: {
            showOffer() {
                if (!this.dragging) this.$emit('show-offer', this.job)
            }
        }
    })

    new Vue({
        el: '#app',
        data() {
            return {
                jobs: [],
                professions: [],
                localisations: [],
                currentProfessionResearched: null,
                professionResearched: null,
                localisationResearched: null,
                currentLocalisationResearched: null,
                images: images,
                showModalOffer: false,
                offerToShow: null,
                carouselDragged: false,
                loading: false,
                hasResearched: false,
                error: {
                    profession: false,
                    localisation: false
                }
            }
        },
        mounted() {
            document.querySelector('body').classList.remove('hidden')
        },
        methods: {
            onSearchProfession(query, loading) {
                loading(true)
                clearTimeout(this.searchProfessionDelay)

                this.searchProfessionDelay = setTimeout(() => {
                    if (query) {
                        let body = new FormData()
                        body.set('profession', query)

                        fetch('/app/platform/profession/completion/get/api', {
                                method: "POST",
                                body: body
                            }
                        ).then(res => {
                            res.json().then(
                                json => {
                                    if (json.success) {
                                        this.professions = json.profession
                                    }
                                    loading(false)
                                }
                            )
                        })
                    } else {
                        loading(false)
                    }
                }, 250)
            },
            searchLocalization(query, loading) {
                loading(true)
                clearTimeout(this.delay)

                let url = this.localisations.length === 0 ? `/app/platform/searchCriteria/location/${query}/null`
                    : `/app/platform/searchCriteria/location/${query}/${this.localisations.map(el => el.value).join(',')}`

                this.delay = setTimeout(() => {
                    if (query !== '' && query.length > 0) {
                        fetch(url).then(res => {
                            res.json().then(json => {
                                if (json.success) {
                                    this.localisations = this.getUnique(JSON.parse(JSON.stringify(this.localisations)).concat(json.results), 'value')
                                }
                                loading(false)
                            })
                        })
                    } else {
                        loading(false)
                    }
                }, 500)
            },
            searchJobs() {
                if (this.areInputsValid()) return

                this.loading = true
                // destroy current carousel
                if (this.jobs.length === 0) $('#jobs-list').trigger('destroy.owl.carousel')
                if (this.jobs.length > 0) $('#job-searched').trigger('destroy.owl.carousel')

                let body = new FormData()
                body.set('criteria', JSON.stringify({
                    profession: this.professionResearched.name,
                    localisation: this.localisationResearched
                }))

                fetch('/app/platform/homepage/offers', {
                        method: "POST",
                        body: body
                    }
                ).then(res => {
                    res.json().then(json => {
                        if (json.success) {
                            let self = this
                            this.jobs = json.jobs
                            this.hasResearched = true
                            this.currentProfessionResearched = this.professionResearched
                            this.currentLocalisationResearched = this.localisationResearched

                            this.$nextTick(() => {
                                $("#job-searched").owlCarousel({
                                    items: 3,
                                    loop: false,
                                    margin: 10,
                                    autoplay: false,
                                    nav: true,
                                    dots: true,
                                    lazyLoad: true,
                                    responsiveClass: true,
                                    responsive: {
                                        0: {items: 1, slideBy: 1},
                                        490: {items: 1, slideBy: 1},
                                        767: {items: 2, slideBy: 2},
                                        991: {items: 3, slideBy: 3},
                                    },
                                    onDrag() {
                                        self.onDragging(true)
                                    },
                                    onDragged() {
                                        self.onDragging(false)
                                    }
                                })
                                window.scrollTo({
                                    top: document.getElementById('jobs').offsetTop,
                                    behavior: 'smooth'
                                })
                            })
                        }
                        this.loading = false
                    })
                })
            },
            showModal(job) {
                this.showModalOffer = true
                this.offerToShow = job
            },
            onDragging(value) {
                this.carouselDragged = value
            },
            getUnique(arr, comp) {
                return arr
                    .map(e => e[comp])
                    .map((e, i, final) => final.indexOf(e) === i && i)
                    .filter(e => arr[e]).map(e => arr[e]);
            },
            areInputsValid() {
                let hasError = false

                if (this.professionResearched === null) {
                    hasError = true
                    this.error.profession = true
                }
                if (this.localisationResearched === null) {
                    hasError = true
                    this.error.localisation = true
                }

                return hasError
            }
        },
        watch: {
            showModalOffer(nVal) {
                document.body.style.overflow = nVal ? 'hidden' : 'unset'
            }
        }
    })

   $("#carousel-companies").owlCarousel({
       loop:true,
       autoplay:true,
       autoplayTimeout:3000,
       autoplayHoverPause:true,
       nav: false,
       dots: false,
       lazyLoad: true,
       responsiveClass:true,
       responsive:{
           0:{
               items: 2,
               nav:false
           },
           490:{
               items: 3,
               nav: false
           },
           767:{
               items:4,
               nav: false,
           },
           1439:{
               items:5,
               nav: false,
           },
       }
   });

    $("#carousel-how-it-work").owlCarousel({
        items: 1,
        loop:true,
        margin: 0,
        autoplay:true,
        autoplayHoverPause:false,
        nav: false,
        dots: true,
        lazyLoad: true,
    });

    $("#jobs-list").owlCarousel({
        items: 3,
        loop:false,
        margin: 10,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        nav: true,
        dots: true,
        lazyLoad: true,
        responsiveClass:true,
        responsive:{
            0:{items: 1, slideBy: 1},
            490:{items: 2, slideBy: 2},
            767:{items:2, slideBy: 2},
            991:{items:3, margin: 25, slideBy: 3},
        }
    });

    $("#carousel-press").owlCarousel({
        items: 6,
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        nav: false,
        dots: false,
        lazyLoad: true,
        responsiveClass:true,
        responsive:{
            0:{
                items: 2,
                nav:false
            },
            490:{
                items: 3,
                nav: false
            },
            767:{
                items:4,
                nav: false,
            },
            1439:{
                items:6,
                nav: false,
            },
        }
    });

    const ratio = .1;
    const options = {
        //root: document.querySelector('#scrollArea'),
        root: null,
        rootMargin: '0px',
        threshold: ratio,
    };

    const handleInstersect = function (entries, observer) {

        entries.forEach(function (entry) {
            if (entry.intersectionRatio > ratio) {
                entry.target.classList.remove('reveal')
                observer.unobserve(entry.target)
            }
        })
    };

    const observer = new IntersectionObserver(handleInstersect, options);

    document.querySelectorAll('.reveal').forEach(function (r) {
        observer.observe(r)
    });

    let myButton = document.getElementById('topButton');

// When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            myButton.style.display = "block";
        } else {
            myButton.style.display = "none";
        }
    }

// When the user clicks on the button, scroll to the top of the document
    /*function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }*/

   /* $("#topButton").click(function () {
        console.log("click for top");
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });*/
    $(document).on('click', '#topButton', function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

    $(document).on('click', '#close-modal', function () {
            $('#modal-redirect').addClass('hidden');
    });

    $(document).on('click', '#call-modal-1', function () {
        $('#modal-redirect').removeClass('hidden');
    });

    $(document).on('click', '#call-modal-2', function () {
        $('#modal-redirect').removeClass('hidden');
    });

    $(document).on('click', '#button-menu', function () {

        $(this).addClass('active');
        $('#menu-list').removeClass('hidden').addClass('mobile-view')
        $('#button_connexion').removeClass('hidden').addClass('mobile-view-connexion');
        $('#modal-backdrop').removeClass('hidden');

        // if ($(this).hasClass('active')) {
        //     $(this).removeClass('active');
        //     $('#menu-list').addClass('hidden').removeClass('mobile-view');
        //     $('#button_connexion').addClass('hidden').removeClass('mobile-view-connexion');
        // } else {
        //     $(this).addClass('active');
        //     $('#menu-list').removeClass('hidden').addClass('mobile-view')
        //     $('#button_connexion').removeClass('hidden').addClass('mobile-view-connexion')
        // }


    });

    $(document).on('click', '#close-button-menu', function () {

        $(this).removeClass('active');
        $('#menu-list').addClass('hidden').removeClass('mobile-view');
        $('#button_connexion').addClass('hidden').removeClass('mobile-view-connexion');
        $('#modal-backdrop').addClass('hidden');

    });

    // Google Analytics Tag Event
    addGoogleTracking();
});

function addGoogleTracking() {

    // View event homepage / engagements
    if (window.location.pathname.includes('engagements')) {
        gtag('event', 'view_engagements', {'event_category': 'landing_page'});
    } else {
        gtag('event', 'view_homepage', {'event_category': 'landing_page'});
    }

    // Event button carousel
    document.querySelectorAll('.carousel-jobcard .owl-dots > button').forEach(function (el, index) {
        el.addEventListener('click', function () {
            const jobCardNumber = index + 1;
            gtag('event', 'button_jobcard', {'event_category': 'landing_page'});
            gtag('event', 'button_jobcard_' + jobCardNumber, {'event_category': 'landing_page'});
        })
    })

    // Event View
    let listElementToCallViewEvent = [
        {
            element: document.getElementById('subscribe_btn_1'), // Event View_inscription1
            viewed: false,
            event: 'view_inscription1'
        },
        {
            element: document.getElementById('try_w2b_btn'), // Event View_inscription2
            viewed: false,
            event: 'view_inscription2'
        },
        {
            element: document.getElementById('show_more_offer_link_container'), // Event View_inscription3
            viewed: false,
            event: 'view_inscription3'
        },
        {
            element: document.getElementById('discover_blog_btn'), // Event View_blog
            viewed: false,
            event: 'view_blog'
        },
        {
            element: document.getElementById('try_w2b_btn2'), // Event View_inscription4
            viewed: false,
            event: 'view_inscription4'
        },
        {
            element: document.getElementById('discover_blog_engagement_btn'), // Event View_engagements_blog
            viewed: false,
            event: 'view_engagements_blog'
        },
        {
            element: document.getElementById('goldenbees_redirect_btn'), // Event View_engagements_gb
            viewed: false,
            event: 'view_engagements_gb'
        },
        {
            element: document.getElementById('discover_offer_btn'), // Event View_engagements_inscription
            viewed: false,
            event: 'view_engagements_inscription'
        },
        {
            element: document.getElementById('newsletter'), // Event View_newsletter
            viewed: false,
            event: 'view_newsletter'
        }
    ]

    callGoogleViewEvents(listElementToCallViewEvent);

    // Custom logic for event button_newsletter => element in iframe + set custom style for text
    let eventNewsletterClicked = false
    let intervalGetIframe = setInterval(function() {
        const iframe = document.querySelector('.hbspt-form iframe')
        if (iframe !== null && iframe.contentWindow.document !== null) {
            iframe.contentWindow.document.querySelector("input[type~='submit']").addEventListener('click', function () {
                if (!eventNewsletterClicked) {
                    eventNewsletterClicked = true
                    gtag('event', 'button_newsletter', {'event_category': 'landing_page'})
                }
            })
            let iframeText = iframe.contentWindow.document.querySelector(".legal-consent-container > div > p")
            let iframeLink= iframe.contentWindow.document.querySelector(".legal-consent-container > div > p > a")

            iframeText.style.color = 'rgba(203,213,224, 0.6)'
            iframeText.style.fontSize = '.875rem'
            iframeText.style.fontWeight = '100'

            iframeLink.style.color = 'rgba(113,128,150)'

            clearIntervalGetIframe()
        }
    }, 1000);

    function clearIntervalGetIframe () {
        clearInterval(intervalGetIframe)
    }
}

/**
 * @param {{element: HTMLElement, viewed: Boolean, event: String}[]} elements
 */
function callGoogleViewEvents (elements) {

    const handler = () => raf( () => {
        elements.forEach(el => {
            if (!el.viewed && el.element !== null) {
                if (checkElementIsInViewport(el.element)) {
                    el.viewed = true
                    gtag('event', el.event, {'event_category': 'landing_page'})
                }
            }
        })
    } )

    handler()
    window.addEventListener( 'scroll', handler )
}

function checkElementIsInViewport (el) {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll

    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
    }

    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
    }

    return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
        || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
}

// requestAnimationFrame
const raf =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function( callback ) {
        window.setTimeout( callback, 1000 / 60 )
    }

function checkUserLogin() {
    let oReq = new XMLHttpRequest();
    oReq.addEventListener("load", function() {
        if (this.response && JSON.parse(this.response).isValid) {
            window.location.replace("/platform/home");
        }
    });
    oReq.open("POST", "/app/platform/user/validate");
    oReq.send();
}
